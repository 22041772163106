
import { defineComponent } from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import useVuelidate, { ErrorObject } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { mapActions, mapGetters } from "vuex";
import MessageBox from "@/components/MessageBox.vue";

// Services
import ScanService from "@/services/ScanService";
import WorkOrderService from "@/services/WorkOrderService";
import EmployeeTimeService from "@/services/EmployeeTimeService";
import ControlService from "@/services/ControlService";
import InputEmployeeID from "../InputEmployeeID.vue";
const scanService = new ScanService(process.env.VUE_APP_ABSTRACTION_API);
const workOrderService = new WorkOrderService(
  process.env.VUE_APP_ABSTRACTION_API,
);

const employeeTimeService = new EmployeeTimeService(
  process.env.VUE_APP_ABSTRACTION_API,
);

export default defineComponent({
  name: "Details",
  components: {
    InputText,
    Button,
    MessageBox,
    InputEmployeeID,
  },
  data() {
    return {
      startJobPayload: {
        employee_id: "",
        function_input: "",
        work_order: "",
        operation: "",
      },
      employee_time: [] as any,
      operations: [] as any[],
      functionDesc: "",
      work_center: "",
      cardHeight: "h-20rem",
      loadStartJob: false,
      direct_flag: false,
      errorMessage: "",
      showErrorDialog: false,
      focusRefName: "",
      rowClass:
        "p-field p-col col-12 m-0 p-0 pb-1 flex justify-content-center r-mono",
    };
  },
  async created() {
    await this.fetchLaborControl();
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      employee_id: {
        required: helpers.withMessage("Employee ID", required),
      },
      employeeName: {
        required: helpers.withMessage("Employee ID", required),
      },
    };
  },
  computed: {
    ...mapGetters({
      getClient: "session/getClient",
      getLaborFunctionTypes: "laborControl/getFunctionTypes",
      getDefaultStartJobFunction: "laborControl/getDefaultStartJobFunction",
    }),
  },
  methods: {
    ...mapActions({
      fetchControl: "control/fetchControl",
      addNotification: "notification/add",
      addSalesOrders: "salesOrder/addSalesOrders",
      removeSalesOrders: "salesOrder/removeSalesOrders",
      clearSalesOrders: "salesOrder/clearSalesOrders",
      fetchLaborControl: "laborControl/fetchLaborControl",
    }),
    clearAll() {
      this.startJobPayload.employee_id = "";
      this.startJobPayload.function_input = "";
      this.functionDesc = "";
      this.work_center = "";
      this.startJobPayload.work_order = "";
      this.startJobPayload.operation = "";
      this.direct_flag = false;
    },
    focusInput(refName: string) {
      if (refName) {
        if (refName === "employee_id") {
          (this.$refs[refName] as any).focus();
        } else {
          (this.$refs[refName] as any).$el.focus();
          (this.$refs[refName] as any).$el.select();
        }
      }
    },
    startJob() {
      if (!this.startJobPayload.employee_id) {
        this.handleErrorAndFocus("No employee entered", "employee_id");
        return;
      } else if (!this.startJobPayload.function_input) {
        this.handleErrorAndFocus("No function entered.", "function_input");
        return;
      }

      if (this.direct_flag) {
        if (!this.startJobPayload.work_order) {
          this.handleErrorAndFocus("No work order entered.", "work_order");
          return;
        } else if (!this.startJobPayload.operation) {
          this.handleErrorAndFocus("No operation entered.", "operation");
          return;
        }
      }

      this.loadStartJob = true;
      scanService
        .postStartJob(this.startJobPayload)
        .then((resp: any) => {
          const notification = {
            dialog: true,
            type: "success",
            message: resp?.message || "Successfully started job.",
          };
          this.addNotification(notification, { root: true });
          this.clearAll();
        })
        .catch((err) => {
          this.handleErrorAndFocus(
            err.response.data.error || "Could not process start job.",
            "employee_id",
          );
        })
        .finally(() => {
          this.loadStartJob = false;
        });
    },
    handleFetchEmployee() {
      employeeTimeService
        .getEmployeeTimeByID(
          this.getClient,
          this.startJobPayload.employee_id,
          "clock_in_date clock_in_time clock_out_date ip_start_date ip_wo_id ip_oper",
          "",
        )
        .then((emptime_resp: any) => {
          if (this.getDefaultStartJobFunction) {
            this.startJobPayload.function_input =
              this.getDefaultStartJobFunction;
            this.handleFunction();
          }

          if (emptime_resp.emptime_items) {
            const emptime = emptime_resp.emptime_items[0];
            this.employee_time = emptime;
            this.focusInput("function_input");
          }
        })
        .catch((err) => {
          this.handleErrorAndFocus(err.toString(), "employee_id");
        });
    },
    handleFunction() {
      let err = null;
      if (this.startJobPayload.function_input) {
        const function_type = this.getLaborFunctionTypes.find((item: any) => {
          return (
            item.function_type ===
            this.startJobPayload.function_input.toUpperCase()
          );
        });
        if (function_type) {
          this.startJobPayload.function_input = function_type.function_type;
          this.functionDesc = function_type.function_description;
          if (!function_type.function_account_number) {
            this.direct_flag = true;
          }
        } else {
          err =
            this.startJobPayload.function_input + " is not a valid function";
        }

        if (!this.direct_flag) {
          this.work_center = "";
          this.startJobPayload.work_order = "";
          this.startJobPayload.operation = "";
        }

        if (err) {
          this.handleErrorAndFocus(err, "function_input");
        } else {
          this.focusInput("work_order");
        }
      }
    },
    handleWorkOrder() {
      if (!this.direct_flag) {
        this.startJobPayload.work_order = "";
        this.handleErrorAndFocus(
          "Function not set to enter a work order. Please update function.",
          "function_input",
        );
        return;
      }
      if (this.startJobPayload.work_order) {
        workOrderService
          .getWorkOrderById(
            this.getClient,
            this.startJobPayload.work_order,
            "wo_id status oper work_center",
            "",
          )
          .then((resp: any) => {
            this.startJobPayload.operation = "";
            this.work_center = "";
            const work_order = resp;

            if (work_order) {
              this.operations = work_order.oper_items;
              this.focusInput("operation");
            }
          })
          .catch((err) => {
            this.handleErrorAndFocus(
              err.toString() || "There was an issue processing the request.",
              "work_order",
            );
          });
      } else {
        this.handleErrorAndFocus("A work order must be entered", "work_order");
      }
    },
    handleOperation() {
      if (!this.direct_flag) {
        this.startJobPayload.operation = "";
        this.work_center = "";
        this.startJobPayload.work_order = "";
        this.handleErrorAndFocus(
          "Function not set to enter an operation. Please update function.",
          "function_input",
        );
        return;
      }

      if (this.startJobPayload.operation && this.startJobPayload.work_order) {
        const operation = this.operations.find(
          (operation) =>
            operation.oper === this.startJobPayload.operation.toUpperCase(),
        );
        if (operation) {
          this.startJobPayload.operation = operation.oper;
          this.work_center = operation.work_center;
        } else {
          this.handleErrorAndFocus(
            `${this.startJobPayload.operation} does not appear on the routing`,
            "operation",
          );
          this.startJobPayload.operation = "";
          this.work_center = "";
        }
      } else {
        this.work_center = "";
      }
    },
    handleErrorAndFocus(message: string, ref: string) {
      this.errorMessage = message;
      this.showErrorDialog = true;
      this.focusRefName = ref;
    },
    clickConfirmErrorDialog() {
      this.showErrorDialog = false;
      this.focusInput(this.focusRefName);
    },
  },
  mounted() {
    this.focusInput("employee_id");
    this.clearAll();
  },
});
